import { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { InfoButton } from 'components/buttons'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Button } from 'baby-design'
// eslint-disable-next-line import/no-extraneous-dependencies
import { AppleLogo } from 'baby-design/icons'
import {
  AppleSignInButtonProps,
  AppleSignInSuccessEvent,
  AppleSignInFailureEvent,
} from './AppleSignInButton.types'
import css from './AppleSignInButton.scss'

const AppleSignInButton = ({
  clickTracking,
  handleBabylistResponse,
  nonce,
  type,
  useDesignSystemButton = false,
}: AppleSignInButtonProps) => {
  const buttonLabel =
    type === 'sign-in' ? 'Log in With Apple' : 'Continue With Apple'
  const appleButtonRef = useRef<HTMLDivElement>(null)

  const [error, setError] = useState('')

  const handleAppleButtonClick = () => {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur()
    }

    clickTracking()

    appleButtonRef.current?.click()
  }

  const onSuccessHandler = async (event: AppleSignInSuccessEvent) => {
    const params = new URLSearchParams()
    params.append('code', event.detail.authorization.code)
    params.append('id_token', event.detail.authorization.id_token)

    const raw = await fetch(`/auth/apple/callback`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-Key-Inflection': 'camel',
      },
      body: params.toString(),
    })

    const response = await raw.json()

    handleBabylistResponse('Apple', response, setError)
  }

  const onFailureHandler = (event: AppleSignInFailureEvent) => {
    // Apple only indicates that the user cancelled the sign in flow, there's no need to report this to the user.
    window.console.log(event.detail.error)
  }

  useEffect(() => {
    document.addEventListener(
      'AppleIDSignInOnSuccess',
      onSuccessHandler as unknown as EventListener
    )

    document.addEventListener(
      'AppleIDSignInOnFailure',
      onFailureHandler as EventListener
    )

    return () => {
      document.removeEventListener(
        'AppleIDSignInOnSuccess',
        onSuccessHandler as unknown as EventListener
      )
      document.removeEventListener(
        'AppleIDSignInOnFailure',
        onFailureHandler as EventListener
      )
    }
  }, [])

  return (
    <>
      <Helmet>
        <script
          src="https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js"
          type="text/javascript"
        />
        <meta content="com.babylist" name="appleid-signin-client-id" />
        <meta content="name email" name="appleid-signin-scope" />
        <meta content={nonce} name="appleid-signin-nonce" />
        <meta
          content={`${window.location.origin}/auth/apple/callback`}
          name="appleid-signin-redirect-uri"
        />
        <meta content="true" name="appleid-signin-use-popup" />
      </Helmet>
      <div className={css.appleSignInButtonContainer}>
        {useDesignSystemButton ? (
          <>
            <Button
              block
              aria-label={buttonLabel}
              className={css.design_system_button__mobile}
              icon={{ icon: <AppleLogo />, side: 'left' }}
              size="md"
              variant="inverted"
              onClick={handleAppleButtonClick}
            >
              {buttonLabel}
            </Button>
            <Button
              block
              aria-label={buttonLabel}
              className={css.design_system_button__desktop}
              icon={{ icon: <AppleLogo />, side: 'left' }}
              size="lg"
              variant="inverted"
              onClick={handleAppleButtonClick}
            >
              {buttonLabel}
            </Button>
          </>
        ) : (
          <InfoButton
            pill
            aria-label={buttonLabel}
            className={css.appleSignInButton}
            onClick={handleAppleButtonClick}
          >
            <span className={css.appleSignInButtonAppleLogo}></span>
            <span className={css.appleSignInButtonText}>{buttonLabel}</span>
          </InfoButton>
        )}
        {error && (
          <span className={`h7 help-block ${css.appleSignInButtonError}`}>
            {error}
          </span>
        )}
        <div
          className={css.appleSignIn}
          data-border="true"
          data-border-radius="50"
          data-color="white"
          data-type={type}
          id="appleid-signin"
          ref={appleButtonRef}
        />
      </div>
    </>
  )
}

export default AppleSignInButton
