import fetch from 'lib/fetch'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UPDATE_CART_SUMMARY } from 'shared/constants'
import { errorMessage, infoMessage } from 'lib/flash-message'
import { CACHE_KEY as CART_CACHE_KEY } from './cart'

const API_PATH = '/store/api/cart_items'

interface CartItem {
  id?: string
  uuid?: string
  price?: number
  productId: number
  quantity: number
}

const fetchCartItem = async (
  { uuid, price, productId, quantity }: CartItem,
  method: 'POST' | 'PATCH'
) => {
  const url = uuid ? `${API_PATH}/${uuid}` : API_PATH
  return fetch(url, {
    method,
    body: JSON.stringify({ price, productId, quantity }),
  })
}

const useCreateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (cartItem: CartItem) => fetchCartItem(cartItem, 'POST'),
    onMutate: () => {
      // TODO: Implement optimistic updates
    },
    onSuccess: () => {
      PubSub.publish(UPDATE_CART_SUMMARY, {
        animateBadge: true,
        showCartDropdown: true,
      })
    },
    onError: () => {
      errorMessage('An error occurred while adding the item to the cart.')
    },
    onSettled: () => {
      queryClient.invalidateQueries(CART_CACHE_KEY)
    },
  })
}

const useUpdateMutation = ({
  flashMessageEnabled = true,
}: { flashMessageEnabled?: boolean } = {}) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (cartItem: CartItem) => fetchCartItem(cartItem, 'PATCH'),
    onMutate: () => {
      // TODO: Implement optimistic updates
    },
    onSuccess: () => {
      PubSub.publish(UPDATE_CART_SUMMARY)
      if (flashMessageEnabled) infoMessage('Your item has been updated!')
    },
    onError: () => {
      if (flashMessageEnabled)
        errorMessage('An error occurred while updating the item in cart.')
    },
    onSettled: () => {
      queryClient.invalidateQueries(CART_CACHE_KEY)
    },
  })
}

export const CartItemsAPI = {
  useCreateMutation,
  useUpdateMutation,
}
