import React, { useEffect, useState } from 'react'
// TODO: remove dependence on react-bootstrap and lodash
import { Col, HelpBlock as BSHelpBlock, Row } from 'react-bootstrap'
import { range } from 'lodash'
import classNames from 'classnames'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Dropdown } from 'baby-design'
import css from './DateSelect.scss'

const today = new Date()
const currentYear = today.getFullYear()
const defaultYears = [currentYear, currentYear + 1]
const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'June',
  'July',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
]

type Option = {
  label: number | string
  value: number
}

const monthsOptions: Option[] = months.map((month, idx) => ({
  label: month,
  value: idx + 1,
}))

const daysOptions: Option[] = range(1, 32).map((day) => ({
  label: day,
  value: day,
}))

export interface DateSelectProps {
  className?: string
  error?: string
  fastForward?: boolean
  help?: string
  label?: string
  onChange: (date: {
    month: number | undefined
    day: number | undefined
    year: number | undefined
  }) => void
  preferFutureDates?: boolean
  years?: number[]
  initialValue?: string | null
  type?: 'primary' | 'inverted'
}

const DateSelect: React.FC<DateSelectProps> = ({
  className,
  error,
  fastForward = true,
  help,
  label,
  onChange,
  preferFutureDates = true,
  years = defaultYears,
  initialValue,
  type,
}) => {
  const [month, setMonth] = useState<Option | undefined>(undefined)
  const [day, setDay] = useState<Option | undefined>(undefined)
  const [year, setYear] = useState<Option | undefined>(undefined)

  useEffect(() => {
    if (initialValue) {
      const [yearStr, monthStr, dayStr] = initialValue.split('-')
      const yearValue = parseInt(yearStr)
      const monthValue = parseInt(monthStr)
      const dayValue = parseInt(dayStr)

      setYear({ value: yearValue, label: yearValue })
      setMonth({ value: monthValue, label: months[monthValue - 1] })
      setDay({ value: dayValue, label: dayValue })
    }
  }, [initialValue])

  const updateMonth = (selectedMonth: Option) => {
    setMonth(selectedMonth)

    if (preferFutureDates && selectedMonth) {
      const currentDate = new Date()
      const currentMonth = currentDate.getMonth() + 1
      const currentYear = currentDate.getFullYear()

      // if no year value is set make a best guess since this is commonly used for due date
      if (!year) {
        // If the month being selected is less than the current month in the current year,
        // then fast-forward to next year
        if (fastForward && selectedMonth.value < currentMonth) {
          setYear({ value: currentYear + 1, label: currentYear + 1 })
        } else {
          // select the current year
          setYear({ value: currentYear, label: currentYear })
        }
      }
    }

    onChange({
      month: selectedMonth.value,
      day: day?.value,
      year: year?.value,
    })
  }

  const updateDay = (selectedDay: Option) => {
    setDay(selectedDay)
    onChange({
      month: month?.value,
      day: selectedDay.value,
      year: year?.value,
    })
  }

  const updateYear = (selectedYear: Option) => {
    setYear(selectedYear)
    onChange({
      month: month?.value,
      day: day?.value,
      year: selectedYear.value,
    })
  }

  return (
    <div className={classNames('input-container', className)}>
      <div>
        <div className={classNames({ 'has-error': error })}>
          <fieldset>
            {label ? <label className="control-label">{label}</label> : null}
            {/*
                row now has the bottom margin instead of the individual selects.
                This let's us position the error message in a consistent fashion.
              */}
            <Row className="mbl" style={{ margin: 0 }}>
              <Col className={classNames('pln', 'prn', css.monthSelect)} xs={4}>
                <Dropdown
                  options={monthsOptions}
                  placeholder="Month"
                  selectClassName="mbn"
                  size="md"
                  type={type}
                  value={month}
                  onChange={(singleValue) => updateMonth(singleValue as Option)}
                />
              </Col>
              <Col className={classNames('pls', 'prn', css.daySelect)} xs={4}>
                <Dropdown
                  options={daysOptions}
                  placeholder="Day"
                  selectClassName="mbn"
                  size="md"
                  type={type}
                  value={day}
                  onChange={(singleValue) => updateDay(singleValue as Option)}
                />
              </Col>
              <Col className="pls prn" xs={4}>
                <Dropdown
                  options={years.map((selectableYear) => ({
                    value: selectableYear,
                    label: selectableYear,
                  }))}
                  placeholder="Year"
                  selectClassName="mbn"
                  size="md"
                  type={type}
                  value={year}
                  onChange={(singleValue) => updateYear(singleValue as Option)}
                />
              </Col>
              {error || help ? (
                <Col className="phn" xs={12}>
                  <BSHelpBlock
                    className={classNames('h6', { 'text-muted': !error })}
                  >
                    {/* if an element has help text and an error just show the error. otherwise show help text */}
                    {error || help}
                  </BSHelpBlock>
                </Col>
              ) : null}
            </Row>
          </fieldset>
        </div>
      </div>
    </div>
  )
}

export default DateSelect
