/* eslint-disable complexity */
import React, { useState } from 'react'
import DateSelect, { DateSelectProps } from 'components/DateSelect/DateSelect'
import { daysUntil, parseLocalISODate } from 'lib/date'
import { dateString as isInvalidDateString } from 'lib/form-validation'

export interface DueDateSelectProps
  extends Omit<DateSelectProps, 'onChange' | 'help'> {
  id: string
  className?: string
  onChange?: (date: string | null) => unknown
  hideHelp?: boolean
}

const DueDateSelect: React.FC<DueDateSelectProps> = ({
  id,
  className,
  onChange,
  hideHelp = false,
  ...dateSelectProps
}) => {
  const [selectedDate, setSelectedDate] = useState<string | null>(null)

  const padNumber = (day: number) => day.toString().padStart(2, '0')

  const onDateChange = ({
    month,
    day,
    year,
  }: {
    month: number | undefined
    day: number | undefined
    year: number | undefined
  }) => {
    let dateString

    if (month && day && year) {
      dateString = `${year}-${padNumber(month)}-${padNumber(day)}`
    } else {
      dateString = null
    }

    setSelectedDate(dateString)
    onChange?.(dateString)
  }

  const getDueDate = () => {
    if (!selectedDate || isInvalidDateString(selectedDate)) {
      return null
    }
    return parseLocalISODate(selectedDate)
  }

  const buildHelpText = () => {
    const dueDate = getDueDate()
    if (!dueDate) {
      return ''
    }
    const days = daysUntil(dueDate)
    if (days > 40 * 7) {
      return "You're trying to get pregnant."
    }
    if (days > 3 * 30) {
      return `You're due in ${Math.round(days / 30)} months... yay!`
    }
    if (days > 1) {
      return `You're due in ${days} days... yay!`
    }
    if (days === 1) {
      return `You're due tomorrow... yay!`
    }
    if (days === 0) {
      return `You're due today... yay!`
    }
    if (days < -365) {
      const years = Math.floor(Math.abs(days) / 365)
      return `You have a${
        years === 8 || years === 18 ? 'n' : ''
      } ${years} year old.`
    }
    if (days < -30) {
      const months = Math.floor(Math.abs(days) / 30)
      return `You have a${months === 8 ? 'n' : ''} ${months} month old.`
    }
    if (days < -7) {
      return `You have a ${Math.floor(Math.abs(days) / 7)} week old.`
    }
    return `You have a ${Math.abs(days)} day old.`
  }

  return (
    <div className={className} id={id}>
      <DateSelect
        {...dateSelectProps}
        help={hideHelp ? undefined : buildHelpText()}
        onChange={onDateChange}
      />
    </div>
  )
}

export default DueDateSelect
