import {
  CredentialResponse,
  GoogleLogin,
  GoogleOAuthProvider,
} from '@react-oauth/google'
import { InfoButton } from 'components/buttons'
import { GOOGLE_LOGIN_API_CLIENT_ID } from 'shared/constants'
import { useState } from 'react'
import classNames from 'classnames'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Button } from 'baby-design'
// eslint-disable-next-line import/no-extraneous-dependencies
import { GoogleLogo } from 'baby-design/icons'
import css from './GoogleSignInButton.scss'

interface GoogleSignInButtonProps {
  clickTracking: () => void
  handleBabylistResponse: (
    provider: 'Google',
    response: unknown,
    setError: (error: string) => void
  ) => void
  label: string
  useDesignSystemButton?: boolean
}

const GoogleSignInButton = ({
  clickTracking,
  handleBabylistResponse,
  label,
  useDesignSystemButton = false,
}: GoogleSignInButtonProps) => {
  const [error, setError] = useState('')

  const toggleGoogleButtonHover = (isHovered: boolean) => {
    let targetClassName = css.googleBlSignInButton
    let hoverClassName = css.googleBlSignInButtonHover

    if (useDesignSystemButton) {
      targetClassName = css.design_system_button
      hoverClassName = css.design_system_button__hover
    }

    const els = window.document.getElementsByClassName(targetClassName)

    for (let i = 0; i < els.length; i += 1) {
      if (isHovered) {
        els[i].classList.add(hoverClassName)
      } else {
        els[i].classList.remove(hoverClassName)
      }
    }
  }

  const handleSuccessResponse = async (
    credentialResponse: CredentialResponse
  ) => {
    const raw = await fetch(`/auth/google/callback`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Key-Inflection': 'camel',
      },
      body: JSON.stringify(credentialResponse),
    })

    const response = await raw.json()

    handleBabylistResponse('Google', response, setError)
  }

  return (
    <div
      className={classNames(css.googleSignInButtonContainer, {
        [css.googleSignInButtonContainer__design_system_button]:
          useDesignSystemButton,
      })}
    >
      <div
        onMouseEnter={() => toggleGoogleButtonHover(true)}
        onMouseLeave={() => toggleGoogleButtonHover(false)}
      >
        <GoogleOAuthProvider clientId={GOOGLE_LOGIN_API_CLIENT_ID}>
          <GoogleLogin
            click_listener={clickTracking}
            logo_alignment="center"
            shape="pill"
            size="large"
            ux_mode="popup"
            width="400"
            onError={() => {
              setError(error)
            }}
            onSuccess={handleSuccessResponse}
          />
        </GoogleOAuthProvider>
      </div>
      {useDesignSystemButton ? (
        <>
          <Button
            block
            aria-label={label}
            className={classNames(
              css.design_system_button,
              css.design_system_button__mobile
            )}
            icon={{ icon: <GoogleLogo />, side: 'left' }}
            size="md"
            variant="inverted"
          >
            {label}
          </Button>
          <Button
            block
            aria-label={label}
            className={classNames(
              css.design_system_button,
              css.design_system_button__desktop
            )}
            icon={{ icon: <GoogleLogo />, side: 'left' }}
            size="lg"
            variant="inverted"
          >
            {label}
          </Button>
        </>
      ) : (
        <InfoButton
          pill
          aria-label={label}
          className={css.googleBlSignInButton}
        >
          <img
            alt="G"
            className={css.googleBlSignInButtonImage}
            src="https://images.babylist.com/image/upload/v1695676201/icons/btn_google_light_normal_ios_borderless.svg"
          />
          <div className={css.googleSignInButtonText}>{label}</div>
        </InfoButton>
      )}
      {error && (
        <span className={`h7 help-block ${css.googleSignInButtonError}`}>
          {error}
        </span>
      )}
    </div>
  )
}

export default GoogleSignInButton
