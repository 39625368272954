import { track, withContextualizedTracking } from 'lib/analytics'
import PlacementsInterstitialModal from 'components/modal/PlacementsInterstitialModal'
import { PLACEMENT_INTERSTITIAL_UNIT } from 'lib/placements/units'
import HeroBanner from './HeroBanner'
import ChecklistSplash from './ChecklistSplash'
import HelloBabyBoxSection from './HelloBabyBoxSection'
import css from './RebrandedHome.scss'
import GuidesSection from './GuidesSection'
import ShopSection from './ShopSection'
import CongratulationsSection from './CongratulationsSection'
import HealthSection from './HealthSection'
import SummarizedNav from './SummarizedNav'

const RebrandedHome = () => (
  <div className={css.HomepageContainer}>
    <HeroBanner />
    <ChecklistSplash />
    <ShopSection />
    <HelloBabyBoxSection />
    <GuidesSection />
    <HealthSection />
    <CongratulationsSection />
    <SummarizedNav />
    <PlacementsInterstitialModal unitId={PLACEMENT_INTERSTITIAL_UNIT} />
  </div>
)

export default withContextualizedTracking({
  eventLocation: track.EventLocation.HOME_PAGE,
})(RebrandedHome)
